import * as React from "react"
import { graphql, Link, type HeadFC, type PageProps } from "gatsby"

import Headers from '../components/Headers'

import Layout from "../components/Layout"

export const query = graphql`
query TeamQuery($lang: String!) {
    markdownRemark(
      fields: {lang: {eq: $lang}}
      fileAbsolutePath: {regex: "/team.md/"}
    ) {
      frontmatter {
        title
      }
      html
    }
  }
`
const IndexPage = ({data, pageContext}: any) => {
  data = data.markdownRemark

  return (
    <Layout lang={pageContext.lang}>
      <div
        className="mainContent teamContent"
        dangerouslySetInnerHTML={{__html: data.html}}/>
    </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    return <Headers
      lang={pageContext.title}
      title="Team"
      location={location}
      />
}
